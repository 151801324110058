export const SEARCH_PATH = '/search/';
export const SEARCH_RESULTS_PER_PAGE = 12;

// [X, Y], where X is results per page when grouped, Y when single content type is selected
export const SEARCH_RESULTS_PER_PAGE_VIDEOS = [4, 16];
export const SEARCH_RESULTS_PER_PAGE_PAGES = [3, 18];
export const SEARCH_RESULTS_PER_PAGE_RESOURCES = [4, 16];
export const SEARCH_RESULTS_PER_PAGE_FAQS = [4, 20];
export const SEARCH_RESULTS_PER_PAGE_FILES = [6, 30];

export const SEARCH_CONTENT_TYPE_ALL = 'all';
export const SEARCH_CONTENT_TYPE_VIDEOS = 'videos';
export const SEARCH_CONTENT_TYPE_PAGES = 'pages';
export const SEARCH_CONTENT_TYPE_RESOURCES = 'resources';
export const SEARCH_CONTENT_TYPE_FAQS = 'faqs';
export const SEARCH_CONTENT_TYPE_FILES = 'files';

export const SEARCH_CONTENT_TYPE_LABELS = {
  [SEARCH_CONTENT_TYPE_ALL]: 'All',
  [SEARCH_CONTENT_TYPE_VIDEOS]: 'Videos',
  [SEARCH_CONTENT_TYPE_PAGES]: 'Website Pages',
  [SEARCH_CONTENT_TYPE_RESOURCES]: 'Articles',
  [SEARCH_CONTENT_TYPE_FAQS]: 'FAQs',
  [SEARCH_CONTENT_TYPE_FILES]: 'Files',
};

export const SEARCH_CONTENT_TYPE_EMPTY_LABELS = {
  [SEARCH_CONTENT_TYPE_ALL]: 'all',
  [SEARCH_CONTENT_TYPE_VIDEOS]: 'video',
  [SEARCH_CONTENT_TYPE_PAGES]: 'website page',
  [SEARCH_CONTENT_TYPE_RESOURCES]: 'article',
  [SEARCH_CONTENT_TYPE_FAQS]: 'FAQ',
  [SEARCH_CONTENT_TYPE_FILES]: 'file',
};
